"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addressWithCountrySchema = exports.addressSchema = void 0;
const zod_1 = require("zod");
exports.addressSchema = zod_1.z.object({
    line1: zod_1.z.string().min(1, 'Ce champ est requis'),
    line2: zod_1.z.string().nullable(),
    city: zod_1.z.string().min(1, 'Ce champ est requis'),
    zip: zod_1.z.string().min(1, 'Ce champ est requis').max(10, 'Ce champ ne peu excéder 10 caracètres'),
});
exports.addressWithCountrySchema = exports.addressSchema.extend({
    countryCode: zod_1.z.string().length(2, 'Le code pays est invalide : il ne doit contenir que 2 caractères. Merci de choisir l\'un des choix disponibles.').describe('The ISO 3166-1 Alpha 2 country code (FR,EN,ES)')
});
