"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortObjects = exports.sortObjectCallback = void 0;
function sortObjectCallback(a, b) {
    return (a.order ?? Infinity) - (b.order ?? Infinity);
}
exports.sortObjectCallback = sortObjectCallback;
function sortObjects(items) {
    return items.sort(sortObjectCallback);
}
exports.sortObjects = sortObjects;
