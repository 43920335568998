"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addressWithCountryObjectToRecord = exports.addressObjectToRecord = exports.removeAddressRecordFields = exports.addressWithCountryRecordToObject = exports.addressRecordToObject = void 0;
function addressRecordToObject(input) {
    return {
        line1: input.addressLine1,
        line2: (input.addressLine2?.length ?? 0) < 1
            ? null
            : input.addressLine2,
        city: input.addressCity,
        zip: input.addressZip,
    };
}
exports.addressRecordToObject = addressRecordToObject;
function addressWithCountryRecordToObject(input) {
    return {
        ...addressRecordToObject(input),
        countryCode: input.addressCountryCode
    };
}
exports.addressWithCountryRecordToObject = addressWithCountryRecordToObject;
function removeAddressRecordFields(input) {
    const { addressLine1, addressLine2, addressCity, addressZip, addressCountryCode, ...output } = input;
    return output;
}
exports.removeAddressRecordFields = removeAddressRecordFields;
function addressObjectToRecord(input) {
    return {
        addressLine1: input.line1,
        addressLine2: input.line2 ?? null,
        addressCity: input.city,
        addressZip: input.zip,
    };
}
exports.addressObjectToRecord = addressObjectToRecord;
function addressWithCountryObjectToRecord(input) {
    return {
        addressLine1: input.line1,
        addressLine2: input.line2 ?? null,
        addressCity: input.city,
        addressZip: input.zip,
        addressCountryCode: input.countryCode
    };
}
exports.addressWithCountryObjectToRecord = addressWithCountryObjectToRecord;
