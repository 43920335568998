"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSiteUrl = void 0;
function getSiteUrl(path) {
    // ! starts with a slash we add one
    if (path !== undefined && path[0] !== '/')
        path = `/${path}`;
    return `${origin}${path ?? ''}`;
}
exports.getSiteUrl = getSiteUrl;
