import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const getApiUrl = () => {
  let url = process.env.API_URL ?? process.env.NEXT_PUBLIC_API_URL;
  if (!url) throw new Error('There is no API_URL nor NEXT_PUBLIC_API_URL env variable defined !');
  if (!url.endsWith('/'))
    url += '/'

  return url;
};

export const isProduction = () => {
  return getApiUrl().includes('selfstock.com') && !getApiUrl().includes('devinterne'); 
}