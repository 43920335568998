"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseErrorsFlat = exports.parseErrors = void 0;
function parseErrors(validationErrors) {
    const output = {};
    for (const error of validationErrors) {
        if (error.messages) {
            output[error.property] = error.messages;
        }
        else {
            output[error.property] = parseErrors(error.errors);
        }
    }
    return output;
}
exports.parseErrors = parseErrors;
function _parseErrorFlatItem(error) {
    const output = [];
    if (error.messages) {
        output.push(...error.messages);
    }
    else {
        for (const e of error.errors) {
            output.push(..._parseErrorFlatItem(e));
        }
    }
    return output;
}
function parseErrorsFlat(errors) {
    return errors.map((e) => _parseErrorFlatItem(e))
        .join('\n');
}
exports.parseErrorsFlat = parseErrorsFlat;
