"use strict";
/* eslint-disable @typescript-eslint/no-explicit-any */
// noinspection ExceptionCaughtLocallyJS
Object.defineProperty(exports, "__esModule", { value: true });
exports.testException = void 0;
/**
 * A test utility to conduct advanced tests on exceptions.
 * @param params
 */
function testException(params) {
    try {
        // Triggers the exception
        params.subject();
        // If we reach here, then no error has been thrown and therefore, the test has failed.
        throw new Error(`It should have thrown a ${params.expectedType.name}`);
    }
    catch (e) {
        // Check the error type
        if (!(e instanceof params.expectedType))
            throw e;
        // Assert good error type, need to ignore to start the app
        // @ts-ignore
        expect(e).toBeInstanceOf(params.expectedType);
        // Calls user tests
        params.test(e);
    }
}
exports.testException = testException;
