"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkoutGTMInfosSchema = exports.completedCheckoutInfosSchema = exports.partialCheckoutInfosSchema = exports.checkoutInfosSchema = exports.CivilityEnum = exports.ContractDurationIntentEnum = exports.StartingDateChoicesEnum = void 0;
const date_utils_1 = require("../date.utils");
const zod_1 = require("zod");
const address_schema_1 = require("./address.schema");
const common_schema_1 = require("./common.schema");
var StartingDateChoicesEnum;
(function (StartingDateChoicesEnum) {
    StartingDateChoicesEnum["TODAY"] = "TODAY";
    StartingDateChoicesEnum["TOMORROW"] = "TOMORROW";
    StartingDateChoicesEnum["OTHER"] = "OTHER";
})(StartingDateChoicesEnum || (exports.StartingDateChoicesEnum = StartingDateChoicesEnum = {}));
var ContractDurationIntentEnum;
(function (ContractDurationIntentEnum) {
    ContractDurationIntentEnum["LONG"] = "LONG";
    ContractDurationIntentEnum["SHORT"] = "SHORT";
})(ContractDurationIntentEnum || (exports.ContractDurationIntentEnum = ContractDurationIntentEnum = {}));
var CivilityEnum;
(function (CivilityEnum) {
    CivilityEnum["PARTICULIER"] = "PARTICULIER";
    CivilityEnum["PROFESSIONNEL"] = "PROFESSIONNEL";
})(CivilityEnum || (exports.CivilityEnum = CivilityEnum = {}));
/**
 * Représente les données du formualire de commande
 * en cours de remplissage
 */
exports.checkoutInfosSchema = zod_1.z.object({
    contractStart: zod_1.z.nativeEnum(StartingDateChoicesEnum),
    contractStartingDate: zod_1.z.coerce
        .date()
        .refine((date) => date >= (0, date_utils_1.roundDate)(new Date()), 'La date choisie ne peut être dans le passé')
        .transform((date) => (0, date_utils_1.roundDate)(date, true)),
    // Si jamais la date est dans le passé, on remplace par la date d'ajd
    // .catch(roundDate(new Date())),
    contractDurationIntent: zod_1.z.nativeEnum(ContractDurationIntentEnum),
    civility: zod_1.z.nativeEnum(CivilityEnum),
    firstName: zod_1.z.string().min(1, 'Ce champ est requis'),
    lastName: zod_1.z.string().min(1, 'Ce champ est requis'),
    creditCardOwnerName: zod_1.z.string().min(1, 'Ce champ est requis'),
    address: address_schema_1.addressWithCountrySchema,
    phone: common_schema_1.phoneSchema.required,
    email: zod_1.z.string().min(1, "Ce champ est requis").email("Le format est invalide (example: mon-mail@domain.com"),
    company: zod_1.z
        .object({
        name: zod_1.z.string().nullable(),
        vatNumber: zod_1.z.string().nullable(),
    })
        .nullable(),
    reference: zod_1.z.string().nullable(),
});
/**
 * Reprend la forme de CheckoutInfos MAIS tous les champs sont nullable.
 * Il sert ensentiellement pour l'envoie des webhooks de checkoutProgression
 * et de payment error pour pouvoir transmettre des informations incomplètes.
 */
exports.partialCheckoutInfosSchema = zod_1.z.object({
    contractStart: zod_1.z.nativeEnum(StartingDateChoicesEnum),
    contractStartingDate: zod_1.z.coerce
        .date()
        .nullable()
        .refine((date) => {
        if (date == null)
            return true;
        return date >= (0, date_utils_1.roundDate)(new Date());
    }, 'La date choisie ne peut être dans le passé')
        .transform((date) => (0, date_utils_1.roundDateNullable)(date, true)),
    // Si jamais la date est dans le passé, on remplace par la date d'ajd
    // .catch(roundDate(new Date())),
    contractDurationIntent: zod_1.z.nativeEnum(ContractDurationIntentEnum),
    civility: zod_1.z.nativeEnum(CivilityEnum),
    firstName: zod_1.z.string().min(1, 'Ce champ est requis').nullable().catch(null),
    lastName: zod_1.z.string().min(1, 'Ce champ est requis').nullable().catch(null),
    creditCardOwnerName: zod_1.z
        .string()
        .min(1, 'Ce champ est requis')
        .nullable()
        .catch(null),
    address: zod_1.z.object({
        line1: zod_1.z.string().min(1, 'Ce champ est requis').nullable().catch(null),
        line2: zod_1.z.string().min(1).nullable().catch(null),
        city: zod_1.z.string().min(1, 'Ce champ est requis').nullable().catch(null),
        zip: zod_1.z
            .string()
            .min(1, 'Ce champ est requis')
            .max(10, 'Ce champ ne peu excéder 10 caracètres')
            .nullable()
            .catch(null),
        countryCode: zod_1.z
            .string()
            .length(2, "Le code pays est invalide : il ne doit contenir que 2 caractères. Merci de choisir l'un des choix disponibles.")
            .describe('The ISO 3166-1 Alpha 2 country code (FR,EN,ES)')
            .nullable()
            .catch(null),
    }),
    phone: common_schema_1.phoneSchema.required,
    email: zod_1.z
        .string()
        .min(1, 'Ce champ est requis')
        .email('Le format est invalide (example: mon-mail@domain.com'),
    company: zod_1.z
        .object({
        name: zod_1.z.string().min(1).nullable().catch(null),
        vatNumber: zod_1.z.string().min(1).nullable().catch(null),
    })
        .nullable(),
    reference: zod_1.z.string().min(1).nullable().catch(null),
});
/**
 * Représente les données du formualire de commande
 * totalement remplies. Contient des règles de validation supplémentaires
 */
exports.completedCheckoutInfosSchema = exports.checkoutInfosSchema.superRefine((data, ctx) => {
    // If contractStart is set to other, check that the date is set
    if (data.contractStart === StartingDateChoicesEnum.OTHER &&
        !data.contractStartingDate) {
        ctx.addIssue({
            code: 'custom',
            message: 'Merci de spécifier une date de début de contrat',
            path: ['contractStartingDate'],
        });
    }
    // Vérifie que le nom de l'entreprise a bien été saisi si professionnel
    if (data.civility === CivilityEnum.PROFESSIONNEL) {
        if (!data.company?.name || data.company.name.length < 1)
            ctx.addIssue({
                code: 'custom',
                message: 'Cette information est requise',
                path: ['companyName'],
            });
    }
});
exports.checkoutGTMInfosSchema = exports.checkoutInfosSchema.pick({
    firstName: true,
    lastName: true,
    phone: true,
    email: true,
    address: true,
});
