"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatZodErrors = void 0;
function formatZodErrors(errors) {
    const formatted = errors.format();
    if (formatted._errors?.length ?? 0 < 1)
        delete formatted._errors;
    const f = Object.entries(formatted).map(([key, value]) => [key, "_errors" in value ? value._errors : value]);
    return Object.fromEntries(f);
}
exports.formatZodErrors = formatZodErrors;
