"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatCenterName = void 0;
/**
 * Formats the center name to include the `town` and `bigCity` properties
 *
 * @param args
 * @returns
 */
function formatCenterName({ town, bigCity, separator = 'hypen' }) {
    let output = town;
    if (bigCity) {
        output += (separator === 'parenthesis')
            ? ` (${bigCity})`
            : ` - ${bigCity}`;
    }
    return output;
}
exports.formatCenterName = formatCenterName;
