import { getApiUrl } from "@web/lib/utils";
import axios, { type AxiosInstance } from "axios";

let http: AxiosInstance;


export function getHttpClient() {
  if (!http) {  
    http = axios.create({
      baseURL: getApiUrl()
    });
  }

  return http;
}