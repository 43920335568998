"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addOneMonth = exports.getNumberOfDaysInTheMonth = exports.parseDateFromInput = exports.convertDateToInputFormat = exports.convertDateToFrenchFormat = exports.dateToRelative = exports.toUTCNullable = exports.toUTC = exports.addDays = exports.roundDateNullable = exports.roundDate = void 0;
/**
 * Rounds a date to 00h00
 * @param date
 */
function roundDate(date, utc = false) {
    if (utc)
        return toUTC(date);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}
exports.roundDate = roundDate;
function roundDateNullable(date, utc = false) {
    if (!date)
        return null;
    return roundDate(date, utc);
}
exports.roundDateNullable = roundDateNullable;
/**
 * Add days to a date
 * @param date
 * @param days
 */
function addDays(date, days) {
    const output = new Date(date);
    output.setDate(date.getDate() + days);
    return output;
}
exports.addDays = addDays;
function toUTC(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}
exports.toUTC = toUTC;
function toUTCNullable(date) {
    if (!date)
        return null;
    return toUTC(date);
}
exports.toUTCNullable = toUTCNullable;
function dateToRelative(start, end) {
    const delta = daysDiff(start, end);
    if (delta === 0) {
        return `aujourd'hui`;
    }
    else if (delta === 1) {
        return `demain`;
    }
    else {
        return `dans ${delta} jours`;
    }
}
exports.dateToRelative = dateToRelative;
function daysDiff(date1, date2) {
    // Set both dates to midnight to ignore the time portion
    const startOfDay1 = roundDate(date1);
    const startOfDay2 = roundDate(date2);
    const oneDay = 1000 * 60 * 60 * 24; // Milliseconds in a day
    const diffInTime = Math.abs(startOfDay2.getTime() - startOfDay1.getTime()); // Difference in milliseconds
    return Math.ceil(diffInTime / oneDay); // Convert to days, rounding up
}
function convertDateToFrenchFormat(date) {
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${date.getFullYear()}`;
}
exports.convertDateToFrenchFormat = convertDateToFrenchFormat;
function convertDateToInputFormat(date) {
    if (!date)
        return '';
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}
exports.convertDateToInputFormat = convertDateToInputFormat;
function parseDateFromInput(value) {
    const parsed = Date.parse(value);
    if (isNaN(parsed))
        return;
    return new Date(parsed);
}
exports.parseDateFromInput = parseDateFromInput;
/**
 * Gets the total number of days in the current month for a given date
 * @param d
 * @returns
 */
function getNumberOfDaysInTheMonth(d) {
    return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
}
exports.getNumberOfDaysInTheMonth = getNumberOfDaysInTheMonth;
function addOneMonth(d) {
    const date = new Date(d);
    date.setMonth(d.getMonth() + 1);
    date.setDate(d.getDate());
    return date;
}
exports.addOneMonth = addOneMonth;
