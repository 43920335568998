import {forwardRef} from "react";
import {buttonBaseStyle, type ButtonProps} from "./base-button";

const GhostButton = forwardRef<HTMLButtonElement, ButtonProps>(function ({className = "", intent = "ghost", children, disabled, onClick, ...props}, ref) {
  return <button ref={ref} className={`${buttonBaseStyle({className, intent, disabled, ...props})}`} {...props.attrs} onClick={onClick} disabled={disabled} type={props.attrs?.type}>
    {children}
  </button>
});


GhostButton.displayName = 'GhostButton';
export { GhostButton };