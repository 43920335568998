import { type BreadCrumbItem } from '../components/breadcrumb';
import {
  type AddressData,
  type CenterEntity,
  type CenterServiceData as CenterServiceListItemDataService,
} from '@repo/api-client';
import { type ReactNode } from 'react';
import Image from 'next/image';
import { LocationUtils } from './location-utils';
import { formatCenterName } from '@repo/utils';
import type { API } from '@web/trpc/types'
import { getApiUrl } from '@web/lib/utils';

export function renderCenterAddress(address: API.Address): ReactNode {
  return (
    <>
      {address.line1} <br />
      {address.line2 && (
        <>
          {address.line2} <br />
        </>
      )}
      {address.zip} - {address.city}
    </>
  );
}

/**
 * À partir d'un openingType, retourne soit 
 * - "24h/24"
 * - "de 6 à 22h"
 * - "de 5 à 22h"
 */
export function formatOpeningType(openingType: API.CenterOpeningType) : string {
  switch (openingType) {
    case "24-7":
      return "24h/24";
    case "6-22":
      return "de 6 à 22h";
    default:
      return "de 5 à 22h";
  }
}

export function renderCenterInlineAddress(address: AddressData): string {
  return `${address.line1}, ${address.zip} ${address.city}`;
}

export function renderCenterDistance(distance: number): string {
  return `${distance.toFixed(0)} ${distance > 1 ? 'kms' : 'km'}`;
}

export function renderCenterServiceIcon(service: CenterServiceListItemDataService): ReactNode | undefined {
  return (
    <Image
      src={resolveServiceIconUrl(service.iconPath)}
      crossOrigin='anonymous'
      width={100}
      height={100}
      alt={`Icône du service ${service.label}`}
    />
  );
}

export function resolveServiceIconUrl(iconPath: string) : string {
  return `${getApiUrl()}${iconPath}`;
}

export function getCenterBreadcrumbItems(
  center: CenterEntity
): BreadCrumbItem[] {
 
  const locationBreadcrumbItems = LocationUtils.computeLocationBreadcrumbItems(center.rootLocation);

  return [
    ...locationBreadcrumbItems,
    {
      label: `${formatCenterName(center)} (${center.rootLocation.code})`,
      url: `/centres-de-selfstockage/${center.path!}`,
    },
  ];
}

export function renderPhoneNumber( phone:string ) : string {
  return phone.replace('+33', '0').replace(/(\d{2})/g, "$1 ").slice(0, -1);
}

export function formatCenterOpeningTime(center: CenterEntity) {
  const accessRegex = /^acces-\d{1}-\d{2}$/;

  const iconPath = center?.pageSettings?.services.find(service => {
    return accessRegex.test(service.slug);
  })?.service.iconPath;

  const is24_7 = center?.pageSettings?.services.find(service => {
    return service.slug === 'acces-24-7';
  }) !== undefined;

  let openingTime = undefined;
  if (center.pageSettings?.contactSection?.openHours?.Monday !== undefined) {

    const {opens, closes} = center.pageSettings.contactSection.openHours.Monday;
    openingTime = `de ${opens.split(':')[0]}h à ${closes.split(':')[0]}h`;
  }
  

  if (is24_7)
    return {
      is24_7,
      openingTime: undefined,
      iconPath: undefined
    };

  return {
    is24_7,
    openingTime,
    iconPath
  };
}